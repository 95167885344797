import React from 'react';
import styled from 'styled-components'

const BaseLink = ({ href, className, children }) => (
    <a href={href} className={className}>
        <div className="content">{children}</div>
    </a>
);

export const Link = styled(BaseLink)`
    font-weight: bold;
    position: relative;
    cursor: pointer;
    color: #000;

    .content {
        display: inline-block;
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #000;
        left: 0;
        bottom: -2px;
        margin: auto;
        transition: 100ms ease-in-out;
    }

    &:hover {
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #000;
            left: 0;
            bottom: 0;
            top: 0;
        }
    }
   
`;
