import React from 'react';
import styled from 'styled-components';
import { Link } from './Link';

const BaseStickyInfo = ({ className, left, href }) => {
    return (
      <div className={className}>
        <div className='item'>
          <div>{left}</div>
          <div>
            {href && (
              <Link href={href}>Read more</Link>
            )}
          </div>
        </div>
        <div className="line"></div>
      </div>
    )
  }

export const StickyInfo = styled(BaseStickyInfo)`
   position: sticky;
   top: 0;
   background-color: #fff;
   padding-top: 1.5rem;
   margin-top: -1.5rem;
   z-index: 20;

   .item {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
   }
`;