import styled from 'styled-components';
import { breakpoints } from '../utils/styling';

export const StyledHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 3rem;
    row-gap: 1.5rem;

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    ${breakpoints("grid-template-columns", "", [
      { 1200: "2fr 1fr" },
    ])};
`

export const TwoColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 3rem;
    row-gap: 1.5rem;

    ${breakpoints("grid-template-columns", "", [
      { 1200: "1fr 1fr" },
    ])};
`