import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../utils/styling';
import {FadeIn} from './FadeIn';

const BaseSection = ({ className, spacing, children }) => {
    return (
        <section className={classNames(className, spacing)}>
            <FadeIn>
                <article>
                    {children}
                </article>
            </FadeIn>
        </section>
    )
}

export const Section = styled(BaseSection)`
    margin: 1.5rem;
    height: auto;

    ${breakpoints("margin", "", [
      { 800: "3rem" },
    ])};

    &.m-0 {
        margin: 0;
    }

    &.mt-0 {
        margin-top: 0rem;
    }
    &.mt-2 {
        margin-top: 6rem;
    }
    &.mt-3 {
        margin-top: 9rem;
    }

    &.mb-0 {
        margin-bottom: 0rem;
    }
    &.mb-1 {
        margin-bottom: 3rem;
    }
    &.mb-2 {
        margin-bottom: 6rem;
    }

    article {}
`;
